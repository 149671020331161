/*
 * IMPORTS
 */
import React from 'react' // Npm: React.js library.
import PropTypes from 'prop-types' // Npm: React.js library.
import Moment from 'moment' // Npm: Moment.js library.
import _ from 'underscore' // Npm: Utility module.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import {
  Box,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useStyleConfig
} from '@chakra-ui/react' // Npm: A simple, modular and accessible component library for React.js.


/*
 * PACKAGES
 */
import DateRangePicker from 'components/DateRangePicker'
import TableSpinner from 'components/TableSpinner'


/*
 * GRAPHS
 */
import CreditHistoryReadQuery from './__query__/index.credit.history.read.query'


/*
 * OBJECTS
 */
function Index({ title, customer }) {
  // Hook assignment.
  const [dateRange, setDateRange] = React.useState({
    'selection': {
      'startDate': Object.DateRangeProvider()?.startDate,
      'endDate': Object.DateRangeProvider()?.endDate,
      'key': 'selection'
    }
  })
  const _QueryCreditHistoryRead = useQuery(CreditHistoryReadQuery, {
    'variables': {
      'customerId': customer?.id,
      'startDate': dateRange?.selection.startDate,
      'endDate': dateRange?.selection.endDate
    },
    'fetchPolicy': Object.React.App.fetchPolicy,
    'pollInterval': Object.React.App.pollInterval
  })
  const _styles = useStyleConfig('Card')
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Component assignment..
  const TableToRender = () => (
    <TableContainer
      w='100%'
      border='1px solid rgba(216, 227, 252, 1)'
      borderRadius='15px'
      minH='400px'
      maxH='400px'
      overflowY='scroll'>
      {
        _QueryCreditHistoryRead.loading ? (
          <Flex w='100%' h='100%' display='flex' alignItems='center' justifyContent='center'>
            <TableSpinner isLoading={true} />
          </Flex>
        ) : 0 === _QueryCreditHistoryRead?.data?.CreditHistoryRead?.length || (_QueryCreditHistoryRead?.data?.CreditHistoryRead?.length > 0 && _.every(_QueryCreditHistoryRead?.data?.CreditHistoryRead, j => j.status !== 'READ_SUCCESSFUL')) ? (
          <Flex w='100%' h='100%' display='flex' alignItems='center' justifyContent='center'>
            <TableSpinner isLoading={false} isEmpty={true} />
          </Flex>
        ) : (
          <Table colorScheme='brand' size='sm' width='100%'>
            <Thead bg='rgba(244, 247, 254, 1)'>
              <Tr>
                <Th
                  height='40px'
                  color='rgba(141, 151, 182, 1)'
                  fontWeight='300px'
                  borderRight='1px solid rgba(216, 227, 252, 1)'
                  fontFamily='DM Sans'>
                  <Flex justify='center' align='center'>
                    S.No.
                  </Flex>{' '}
                </Th>
                <Th
                  w='100px'
                  height='40px'
                  color='rgba(141, 151, 182, 1)'
                  fontWeight='300px'
                  borderRight='1px solid rgba(216, 227, 252, 1)'
                  fontFamily='DM Sans'>
                  <Flex justify='center' align='center'>
                    Payment
                  </Flex>{' '}
                </Th>
                <Th
                  height='40px'
                  color='rgba(141, 151, 182, 1)'
                  fontWeight='300px'
                  borderRight='1px solid rgba(216, 227, 252, 1)'
                  fontFamily='DM Sans'>
                  <Flex justify='center' align='center'>
                    Balance
                  </Flex>{' '}
                </Th>
                <Th
                  height='40px'
                  color='rgba(141, 151, 182, 1)'
                  fontWeight='300px'
                  borderRight='1px solid rgba(216, 227, 252, 1)'
                  fontFamily='DM Sans'>
                  <Flex justify='center' align='center'>
                    Credited On
                  </Flex>{' '}
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {
                [...(_QueryCreditHistoryRead?.data?.CreditHistoryRead ?? []), ...(_.first(_QueryCreditHistoryRead?.data?.CreditHistoryRead)?.History ?? [])]?.map?.(({ balance, limit, creditedAt }, index) => (
                  <Tr key={String.random(8)} bg={index === 0 ? 'gray.200' : void 0}>
                    <Td
                      height='42px'
                      isNumeric
                      borderRight='1px solid rgba(216,227,252,1)'>
                      {' '}
                      <Text
                        color='rgba(43, 54, 116, 1)'
                        fontWeight='700'
                        textAlign='center'
                        fontSize='13px'>
                        {' '}
                        {index + 1}
                      </Text>
                    </Td>
                    <Td border='1px solid rgba(216,227,252,1)'>
                      <Flex justify='center' align='center'>
                        {(limit ?? 0)?.toFixed(2)}
                      </Flex>
                    </Td>
                    <Td textAlign='center' borderRight='1px solid rgba(216,227,252,1)'>
                      <Text
                        color='rgba(43, 54, 116, 1)'
                        fontWeight='700'
                        fontSize='13px'>
                        {' '}
                        {(balance ?? 0)?.toFixed(1)}{' '}
                      </Text>
                    </Td>
                    <Td border='1px solid rgba(216,227,252,1)'>
                      <Flex justify='center' align='center'>
                        {Moment(creditedAt).format('YYYY-MM-DD')}
                      </Flex>
                    </Td>
                  </Tr>
                ))
              }
            </Tbody>
          </Table>
        )
      }
    </TableContainer>
  )

  // Return component.
  return (
    <Box
      __css={_styles}
      align='center'
      display='flex'
      direction='column'
      w='100%'
      p={_isCurrentViewMobile ? '12px' : '22px'}
      gap={_isCurrentViewMobile ? '12px' : '22px'}
      fontSize={['clamp(12px, 1.5vw, 15px)']}
      boxShadow='14px 17px 40px 4px rgba(112, 144, 176, 0.17)'>
      <Flex justify='space-between' alignItems='center' width='100%'>
        <Text
          color='rgba(43,54,116,1)'
          fontWeight='700'
          fontSize={{ 'base': 'sm', 'md': 'md' }}
          textAlign='left'>
          {title ?? 'Your Credit Report'}
        </Text>
        <DateRangePicker
          startDate={dateRange?.selection?.startDate}
          endDate={dateRange?.selection?.endDate}
          setSelectDate={setDateRange}
          isLoading={_QueryCreditHistoryRead?.loading} />
      </Flex>
      <TableToRender />
    </Box>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'customer': PropTypes.object.isRequired,
  'title': PropTypes.string
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'customer': __state.Customer })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
