/*
 * IMPORTS
 */
import React from 'react' // Npm: React.js library.
import PropTypes from 'prop-types' // Npm: React.js library.
import _ from 'underscore' // Npm: Utility module.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useLazyQuery, useQuery } from '@apollo/client' // Npm: Apollo client.
import { HiDocumentText } from 'react-icons/hi2' // Npm: React icons.
import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useStyleConfig
} from '@chakra-ui/react' // Npm: A simple, modular and accessible component library for React.js.


/*
 * PACKAGES
 */
import DateRangePicker from 'components/DateRangePicker'
import TableSpinner from 'components/TableSpinner'
import { MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import VendorMarginReportQuery from './__query__/index.vendor.marginReport.query'
import VendorAccountReadQuery from './__query__/index.vendorAccount.read.query'


/*
 * STYLES
 */
import { headerStyle, rowStyle } from './index.style'


/*
 * OBJECTS
 */
function Index({ title, passOn, vendor }) {
  // Hook assignment.
  const [dateRange, setDateRange] = React.useState({
    'selection': {
      'startDate': Object.DateRangeProvider()?.startDate,
      'endDate': Object.DateRangeProvider()?.endDate,
      'key': 'selection'
    }
  })
  const [smsRead, setSmsRead] = React.useState([])
  const [vendorAccount, setVendorAccount] = React.useState(void 0)
  const [QueryVendorMarginReport, QueryVendorMarginReportResponse] = useLazyQuery(VendorMarginReportQuery, {
    'variables': {
      'vendorId': passOn?.vendorId ?? vendor?.id,
      'startDate': dateRange?.selection.startDate,
      'endDate': dateRange?.selection.endDate
    }
  })
  const _QueryVendorAccountRead = useQuery(VendorAccountReadQuery, { 'variables': { 'vendorId': passOn?.vendorId ?? vendor?.id }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })
  const _styles = useStyleConfig('Card')
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Event handler
  React.useEffect(() => {
    // Async handler.
    const _Async = async () => {
      // Refetch data on date range change.
      const { data, error } = await QueryVendorMarginReport({ 'variables': { 'vendorId': passOn?.vendorId ?? vendor?.id, 'startDate': dateRange.selection.startDate, 'endDate': dateRange.selection.endDate } })

      // If getting data caught exception then report failure.
      if (error instanceof Error) throw error

      // Const assignment.
      const _report = _.compact(Object.entries(data?.VendorMarginReport?._report ?? {}))

      // If data is not empty then update it.
      if (0 < _report?.length) {
        // Const assignment.
        const _totalsumup = {}

        // Merge all by country names.
        for (const [__, value] of _.compact(Object.values(_report))) {
          // Break by countryName.
          for (const [key, value2] of Object.entries(value)) {
            // If country name is not in the list then add it.
            if (!_totalsumup[key]) _totalsumup[key] = value2
          }
        }

        // Update the state.
        setSmsRead(Object.entries(_totalsumup))
      }
    }; _Async().catch(e => e).then(j => j)
  }, [dateRange, vendorAccount])

  // Return component.
  return (
    <Flex
      __css={_styles}
      align='center'
      display='flex'
      direction='column'
      w='100%'
      p={_isCurrentViewMobile ? '12px' : '22px'}
      gap={_isCurrentViewMobile ? '12px' : '22px'}
      boxShadow='14px 17px 40px 4px rgba(112, 144, 176, 0.17)'>
      <Flex justify='space-between' alignItems='center' width='100%'>
        <Flex flexDir='column' align='center' justifyContent='center' gap='12px'>
          <Flex gap='6px' color='brand.500'>
            <HiDocumentText size={21} />
            <Text fontSize='lg' color='brand.500' textAlign='left'>
              {title ?? 'Billing Report'}
            </Text>
          </Flex>
          <DateRangePicker
            startDate={dateRange?.selection?.startDate}
            endDate={dateRange?.selection?.endDate}
            setSelectDate={setDateRange}
            isLoading={QueryVendorMarginReportResponse?.loading} />
        </Flex>
        <Flex gap='12px' flexDir='row' flexWrap='wrap'>
          <MemoizedSelect
            name='vendorId'
            placeholder='Select Vendor Account'
            w='210px'
            bg='purple.50'
            color='purple'
            data={passOn?.vendorId ?? vendor?.id}
            options={(_QueryVendorAccountRead?.data?.VendorAccountRead ?? [])?.map(j => j.displayName ? `${j.displayName} (${j.id})` : void 0)}
            onChange={e => setVendorAccount(e.target?.value?.split('(')?.[1]?.split(')')?.[0])}
          />
        </Flex>
      </Flex>
      <TableContainer
        flex={1}
        display='flex'
        borderRadius='15px'
        outline='1px solid #C5CFE8'>
        <Table variant='simple' size='md'>
          <Thead bg='rgba(244, 247, 254, 1)'>
            <Tr>
              <Th
                style={headerStyle}
                borderRight='1px solid rgba(216, 227, 252, 1)'>
                S.No.
              </Th>
              <Th
                style={headerStyle}
                borderRight='1px solid rgba(216, 227, 252, 1)'>
                Country Name
              </Th>
              <Th
                style={headerStyle}
                borderRight='1px solid rgba(216, 227, 252, 1)'>
                Total Cost
              </Th>
              <Th
                style={headerStyle}
                borderRight='1px solid rgba(216, 227, 252, 1)'>
                Sms Count
              </Th>
              <Th
                style={headerStyle}
                borderRight='1px solid rgba(216, 227, 252, 1)'>
                Parts Count
              </Th>
              <Th
                style={headerStyle}
                borderRight='1px solid rgba(216, 227, 252, 1)'>
                Delivered Cost
              </Th>
              <Th
                style={headerStyle}
                borderRight='1px solid rgba(216, 227, 252, 1)'>
                Failed Cost
              </Th>
              <Th style={headerStyle}>
                Pending Cost
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {
              QueryVendorMarginReportResponse.loading ? (
                <TableSpinner isLoading={true} />
              ) : 0 === smsRead?.length ? (
                <TableSpinner isLoading={false} isEmpty={true} />
              ) : smsRead.map((sms, index) => _.isEmpty(sms) ? void 0 : (
                <Tr key={String.random(8)}>
                  <Td style={rowStyle} isNumeric borderRight='1px solid rgba(216,227,252,1)'>
                    {index + 1}
                  </Td>
                  <Td style={rowStyle} textAlign='center' borderRight='1px solid rgba(216,227,252,1)'>
                    {sms?.[0] ?? '-'}
                  </Td>
                  <Td style={rowStyle} textAlign='center' borderRight='1px solid rgba(216,227,252,1)'>
                    {sms?.[1]?.cost?.toFixed(2) ?? 0}
                  </Td>
                  <Td style={rowStyle} textAlign='center' borderRight='1px solid rgba(216,227,252,1)'>
                    {sms?.[1]?.totalsmscount ?? 0}
                  </Td>
                  <Td style={rowStyle} textAlign='center' borderRight='1px solid rgba(216,227,252,1)'>
                    {sms?.[1]?.totalparts ?? 0}
                  </Td>
                  <Td style={rowStyle} textAlign='center' borderRight='1px solid rgba(216,227,252,1)'>
                    {sms?.[1]?.deliveredcost?.toFixed(2) ?? 0}
                  </Td>
                  <Td style={rowStyle} textAlign='center' borderRight='1px solid rgba(216,227,252,1)'>
                    {sms?.[1]?.failedcost?.toFixed(2) ?? 0}
                  </Td>
                  <Td style={rowStyle} textAlign='center'>
                    {sms?.[1]?.acceptedcost?.toFixed(2) ?? 0}
                  </Td>
                </Tr>
              ))
            }
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object.isRequired,
  'title': PropTypes.string
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn, 'vendor': __state.Vendor })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
